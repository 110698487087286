import React, { useState } from "react";

import "./RadnoVreme.css";

function RadnoVreme() {
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState("sve");
  const [location, setLocation] = useState("sve");
  const [selectedObject, setSelectedObject] = useState(null);

  const radnoVremeData = [
    {
      id: 1,
      name: "Bođirković - Borovo naselje",
      category: "prodavnica",
      location: "Borovo naselje",
      hours: {
        monday: "08:00-12:00",
        tuesday: "07:00-14:30",
        wednesday: "07:00-14:30",
        thursday: "07:00-14:30",
        friday: "07:00-14:30",
        saturday: "07:00-13:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 2,
      name: "Bođirković - Borovo",
      category: "prodavnica",
      location: "Borovo",
      hours: {
        monday: "08:00-15:30",
        tuesday: "07:00-20:00",
        wednesday: "07:00-20:00",
        thursday: "07:00-20:00",
        friday: "07:00-20:00",
        saturday: "07:00-20:00",
        sunday: "08:00-12:00",
      },
    },
    {
      id: 3,
      name: "Karan - Vukovar",
      category: "prodavnica",
      location: "Vukovar",
      hours: {
        monday: "06:00-16:00",
        tuesday: "06:00-16:00",
        wednesday: "06:00-16:00",
        thursday: "06:00-16:00",
        friday: "06:00-16:00",
        saturday: "06:00-14:00",
        sunday: "08:00-12:00",
      },
    },
    {
      id: 4,
      name: "Karan - Borovo naselje",
      category: "prodavnica",
      location: "Borovo naselje",
      hours: {
        monday: "07:00-20:00",
        tuesday: "07:00-20:00",
        wednesday: "07:00-20:00",
        thursday: "07:00-20:00",
        friday: "07:00-20:00",
        saturday: "07:00-20:00",
        sunday: "07:00-13:00",
      },
    },
    {
      id: 5,
      name: "Papirus - Borovo naselje",
      category: "prodavnica",
      location: "Borovo naselje",
      hours: {
        monday: "07:00-20:30",
        tuesday: "07:00-20:30",
        wednesday: "07:00-20:30",
        thursday: "07:00-20:30",
        friday: "07:00-20:30",
        saturday: "08:00-18:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 6,
      name: "Pošta - Borovo naselje",
      category: "pošta",
      location: "Borovo naselje",
      hours: {
        monday: "08:00-18:00",
        tuesday: "08:00-18:00",
        wednesday: "08:00-18:00",
        thursday: "08:00-18:00",
        friday: "08:00-18:00",
        saturday: "Zatvoreno",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 7,
      name: "Pošta - Vukovar",
      category: "pošta",
      location: "Vukovar",
      hours: {
        monday: "08:00-19:00",
        tuesday: "08:00-19:00",
        wednesday: "08:00-19:00",
        thursday: "08:00-19:00",
        friday: "08:00-19:00",
        saturday: "08:00-13:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 8,
      name: "Konzum - Borovo naselje",
      category: "supermarket",
      location: "Borovo naselje",
      hours: {
        monday: "07:00-21:00",
        tuesday: "07:00-21:00",
        wednesday: "07:00-21:00",
        thursday: "07:00-21:00",
        friday: "07:00-21:00",
        saturday: "07:00-21:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 9,
      name: "Konzum - Vukovar",
      category: "supermarket",
      location: "Vukovar",
      hours: {
        monday: "07:00-21:00",
        tuesday: "07:00-21:00",
        wednesday: "07:00-21:00",
        thursday: "07:00-21:00",
        friday: "07:00-21:00",
        saturday: "07:00-21:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 10,
      name: "Kaufland - Vukovar",
      category: "supermarket",
      location: "Vukovar",
      hours: {
        monday: "07:00-21:00",
        tuesday: "07:00-21:00",
        wednesday: "07:00-21:00",
        thursday: "07:00-21:00",
        friday: "07:00-21:00",
        saturday: "07:00-21:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 11,
      name: "Lidl - Vukovar",
      category: "supermarket",
      location: "Vukovar",
      hours: {
        monday: "08:00-21:00",
        tuesday: "08:00-21:00",
        wednesday: "08:00-21:00",
        thursday: "08:00-21:00",
        friday: "08:00-21:00",
        saturday: "08:00-21:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 12,
      name: "Ljekarna Joukhadar - Borovo naselje, Domovinskog rata",
      category: "ljekarna",
      location: "Borovo naselje",
      hours: {
        monday: "09:00-18:00",
        tuesday: "09:00-18:00",
        wednesday: "09:00-18:00",
        thursday: "09:00-18:00",
        friday: "09:00-18:00",
        saturday: "10:00-19:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 13,
      name: "Ljekarna Joukhadar - Borovo naselje, Blage Zadre",
      category: "ljekarna",
      location: "Borovo naselje",
      hours: {
        monday: "07:00-20:00",
        tuesday: "07:00-20:00",
        wednesday: "07:00-20:00",
        thursday: "07:00-20:00",
        friday: "07:00-20:00",
        saturday: "08:00-13:00",
        sunday: "Zatvoreno",
      },
    },
    {
      id: 14,
      name: "PoPizza - Borovo naselje",
      category: "restoran",
      location: "Borovo naselje",
      hours: {
        monday: "12:00-22:00",
        tuesday: "12:00-22:00",
        wednesday: "12:00-22:00",
        thursday: "12:00-22:00",
        friday: "12:00-22:00",
        saturday: "12:00-22:00",
        sunday: "12:00-22:00",
      },
    },
    {
      id: 15,
      name: "Restoran Nada",
      category: "restoran",
      location: "Vukovar",
      hours: {
        monday: "09:00-18:00",
        tuesday: "09:00-18:00",
        wednesday: "09:00-18:00",
        thursday: "09:00-18:00",
        friday: "09:00-18:00",
        saturday: "10:00-19:00",
        sunday: "10:00-19:00",
      },
    },
    {
      id: 16,
      name: "Restoran Mornar",
      category: "restoran",
      location: "Vukovar",
      hours: {
        monday: "08:00-23:00",
        tuesday: "08:00-23:00",
        wednesday: "08:00-23:00",
        thursday: "08:00-23:00",
        friday: "08:00-23:00",
        saturday: "08:00-23:00",
        sunday: "08:00-23:00",
      },
    },
    {
      id: 17,
      name: "Restoran Stari toranj",
      category: "restoran",
      location: "Vukovar",
      hours: {
        monday: "09:00-23:00",
        tuesday: "09:00-23:00",
        wednesday: "09:00-23:00",
        thursday: "09:00-23:00",
        friday: "09:00-23:00",
        saturday: "09:00-23:00",
        sunday: "10:00-22:00",
      },
    },
    {
      id: 18,
      name: "Policijska postaja",
      category: "Gradske institucije",
      location: "Vukovar",
      hours: {
        monday: "07:30-14:30",
        tuesday: "09:00-16:30",
        wednesday: "07:30-14:30",
        thursday: "07:30-14:30",
        friday: "07:30-14:30",
        saturday: "Zatvoreno",
        sunday: "Zatvoreno",
      },
    },
  ];

  const getCurrentDay = () => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const today = new Date().getDay();
    return days[today];
  };

  const checkIfOpen = (hours) => {
    if (hours === "Zatvoreno") return false;

    const [open, close] = hours.split("-");
    const currentTime = new Date().toTimeString().slice(0, 5);
    return currentTime >= open && currentTime <= close;
  };

  const filteredData = radnoVremeData.filter((item) => {
    const matchesSearch = item.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCategory = category === "sve" || item.category === category;
    const matchesLocation = location === "sve" || item.location === location;
    return matchesSearch && matchesCategory && matchesLocation;
  });

  const renderWeeklyHours = (hours) => {
    return (
      <div className="weekly-hours">
        <h3>Nedeljno radno vreme:</h3>
        <ul>
          {Object.entries(hours).map(([day, time]) => (
            <li key={day}>
              <strong>{day.charAt(0).toUpperCase() + day.slice(1)}:</strong>{" "}
              {time}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="radno-vreme">
      <h1>Radno Vreme Objekata</h1>

      <div className="controls">
        <input
          type="text"
          placeholder="Pretraži po nazivu..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="sve">Sve kategorije</option>
          <option value="prodavnica">Prodavnice</option>
          <option value="supermarket">Supermarketi</option>
          <option value="restoran">Restorani</option>
          <option value="ljekarna">Ljekarne</option>
          <option value="pošta">Pošte</option>
        </select>

        <select value={location} onChange={(e) => setLocation(e.target.value)}>
          <option value="sve">Sve lokacije</option>
          <option value="Vukovar">Vukovar</option>
          <option value="Borovo">Borovo</option>
          <option value="Borovo naselje">Borovo naselje</option>
        </select>
      </div>

      <table className="radno-vreme-table">
        <thead>
          <tr>
            <th>Naziv</th>
            <th>Kategorija</th>
            <th>Lokacija</th>
            <th>Radno Vreme ({getCurrentDay()})</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => {
            const hoursToday = item.hours[getCurrentDay()];
            const isOpen = checkIfOpen(hoursToday);

            return (
              <React.Fragment key={item.id}>
                <tr
                  className="clickable-row"
                  onClick={() =>
                    setSelectedObject(
                      selectedObject?.id === item.id ? null : item
                    )
                  }
                >
                  <td>{item.name}</td>
                  <td>{item.category}</td>
                  <td>{item.location}</td>
                  <td>{hoursToday}</td>
                  <td>
                    <span className={`status ${isOpen ? "open" : "closed"}`}>
                      {isOpen ? "Otvoreno" : "Zatvoreno"}
                    </span>
                  </td>
                </tr>

                {selectedObject?.id === item.id && (
                  <tr className="details-row">
                    <td colSpan="5">{renderWeeklyHours(item.hours)}</td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default RadnoVreme;
