import React, { useState } from "react";
import "./RedVoznjeTable.css";

const getNextThreeDepartures = (vremena) => {
  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentTime = currentHours * 60 + currentMinutes;

  const upcomingTimes = vremena
    .map((time) => {
      const [hours, mins] = time.split(":").map(Number);
      return hours * 60 + mins;
    })
    .filter((minutes) => minutes >= currentTime)
    .sort((a, b) => a - b)
    .slice(0, 3)
    .map((minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours}:${mins < 10 ? "0" + mins : mins}`;
    });

  return upcomingTimes.length > 0
    ? upcomingTimes
    : ["Nema više polazaka danas"];
};

function RedVoznjeTable({ data }) {
  const [selectedLinija, setSelectedLinija] = useState(null);

  return (
    <div className="red-voznje-container">
      <div className="schedule-card-container">
        {data.map((linija) =>
          linija.stanice.map((stanica) => {
            const nextThree = getNextThreeDepartures(stanica.vremena);
            return (
              <div
                key={`${linija.id}-${stanica.naziv}`}
                className="schedule-row"
                onClick={() => setSelectedLinija(linija)}
              >
                <div className="row-item" data-label="Linija">
                  {linija.linija}
                </div>
                <div className="row-item" data-label="Stanica">
                  {stanica.naziv}
                </div>
                <div className="row-item" data-label="Sledeća Tri Polaska">
                  {nextThree.join(", ")}
                </div>
              </div>
            );
          })
        )}
      </div>

      {selectedLinija && (
        <div className="full-schedule">
          <h2>{selectedLinija.linija}</h2>

          <div className="full-schedule-container">
            {selectedLinija.stanice.map((stanica) => (
              <div
                key={`${selectedLinija.id}-${stanica.naziv}`}
                className="station-schedule"
              >
                <h3 className="station-name">{stanica.naziv}</h3>
                <div className="time-slots">
                  {stanica.vremena.map((vreme, index) => (
                    <span key={index} className="time-slot">
                      {vreme}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default RedVoznjeTable;
