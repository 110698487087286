import React from "react";
import "./OglasiLista.css";

function OglasiLista({ oglasi, odobriOglas }) {
  return (
    <div className="oglas-lista">
      <h2>Objavljeni Oglasi</h2>
      {oglasi.length === 0 ? (
        <p>Nema oglasa za prikaz.</p>
      ) : (
        oglasi.map((oglas) => (
          <div
            key={oglas.id}
            className={`oglas ${oglas.odobren ? "odobren" : "neodobren"}`}
          >
            <h3>{oglas.naslov}</h3>
            <p>{oglas.opis}</p>
            {oglas.slika && <img src={oglas.slika} alt={oglas.naslov} />}
            <p>
              <strong>Kontakt:</strong> {oglas.kontakt}
            </p>
            {!oglas.odobren && (
              <button onClick={() => odobriOglas(oglas.id)}>Odobri</button>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default OglasiLista;
