import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  return (
    <header className="header">
      <div className="header__logo">
        <h1>AllAboutVu</h1>
      </div>
      <nav className="header__nav">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/radno-vreme">Radno Vreme</Link>
          </li>
          <li>
            <Link to="/red-voznje">Red Voznje</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
