import React, { useState, useEffect, useMemo } from "react";
import RedVoznjeTable from "../components/RedVoznjeTable";
import RedVoznjeControls from "../components/RedVoznjeControls";
import "./RedVoznje.css";

function RedVoznje() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLine, setSelectedLine] = useState(null);
  const [nextDepartures, setNextDepartures] = useState([]);
  const [showInitialLines, setShowInitialLines] = useState(true);
  const [selectedStation, setSelectedStation] = useState(null);

  const redVoznjeData = useMemo(
    () => [
      {
        id: 1,
        linija: "Dvanaest redarstvenika - Sajmište",
        stanice: [
          {
            naziv: "Dvanaest redarstvenika",
            vremena: [
              "5:25",
              "6:10",
              "7:10",
              "7:55",
              "8:40",
              "9:25",
              "10:10",
              "10:55",
              "11:40",
              "12:25",
              "13:10",
              "13:45",
              "15:25",
              "16:55",
              "18:25",
              "19:55",
            ],
          },
          {
            naziv: "Borovo naselje",
            vremena: [
              "5:30",
              "6:15",
              "7:15",
              "8:00",
              "8:45",
              "9:30",
              "10:15",
              "11:00",
              "11:45",
              "12:30",
              "13:15",
              "14:00",
              "15:30",
              "17:00",
              "18:30",
              "20:00",
            ],
          },
          {
            naziv: "Centar",
            vremena: [
              "5:45",
              "6:30",
              "7:30",
              "8:15",
              "9:00",
              "9:45",
              "10:30",
              "11:15",
              "12:00",
              "12:45",
              "13:30",
              "14:15",
              "15:45",
              "17:15",
              "18:45",
              "20:15",
            ],
          },
          {
            naziv: "Sajmište",
            vremena: [
              "6:00",
              "6:45",
              "7:45",
              "8:30",
              "9:15",
              "10:00",
              "10:45",
              "11:30",
              "12:15",
              "13:00",
              "13:45",
              "14:30",
              "16:00",
              "17:30",
              "19:00",
              "20:30",
            ],
          },
        ],
      },
      {
        id: 2,
        linija: "Sajmište - Dvanaest redarstvenika",
        stanice: [
          {
            naziv: "Sajmište",
            vremena: [
              "5:30",
              "6:15",
              "7:00",
              "7:45",
              "8:30",
              "9:15",
              "10:00",
              "10:45",
              "11:30",
              "12:15",
              "13:00",
              "13:45",
              "14:30",
              "16:00",
              "17:30",
              "19:00",
              "20:30",
            ],
          },
          {
            naziv: "Centar",
            vremena: [
              "5:35",
              "6:25",
              "7:10",
              "7:55",
              "8:40",
              "9:25",
              "10:10",
              "10:55",
              "11:40",
              "12:25",
              "13:10",
              "13:55",
              "14:40",
              "16:10",
              "17:40",
              "19:10",
              "20:40",
            ],
          },
          {
            naziv: "Borovo naselje",
            vremena: [
              "5:45",
              "6:40",
              "7:25",
              "8:10",
              "8:55",
              "9:40",
              "10:25",
              "11:10",
              "11:55",
              "12:40",
              "13:25",
              "14:10",
              "14:45",
              "16:25",
              "17:55",
              "19:25",
              "20:55",
            ],
          },
          {
            naziv: "Dvanaest redarstvenika",
            vremena: [
              "5:50",
              "6:45",
              "7:30",
              "8:15",
              "9:00",
              "9:45",
              "10:30",
              "11:15",
              "12:00",
              "12:45",
              "13:30",
              "14:15",
              "14:50",
              "16:30",
              "18:00",
              "19:30",
              "21:00",
            ],
          },
        ],
      },
      {
        id: 3,
        linija: "Lipovača - Novo groblje",
        stanice: [
          {
            naziv: "Lipovača",
            vremena: [
              "6:00",
              "7:20",
              "8:40",
              "10:10",
              "12:25",
              "13:15",
              "15:45",
              "17:05",
              "20:05",
            ],
          },
          {
            naziv: "Trpinjska cesta",
            vremena: [
              "5:15",
              "6:05",
              "6:40",
              "7:25",
              "8:10",
              "8:55",
              "9:40",
              "10:25",
              "11:10",
              "11:55",
              "12:30",
              "13:25",
              "14:35",
              "15:50",
              "17:20",
              "18:50",
              "20:10",
            ],
          },
          {
            naziv: "Borovo naselje",
            vremena: [
              "5:25",
              "6:15",
              "6:50",
              "7:35",
              "8:20",
              "9:05",
              "9:50",
              "10:35",
              "11:20",
              "12:05",
              "12:40",
              "13:35",
              "14:45",
              "16:00",
              "17:30",
              "19:05",
              "20:20",
            ],
          },
          {
            naziv: "Budžak",
            vremena: [
              "5:27",
              "6:17",
              "6:52",
              "7:37",
              "8:22",
              "9:07",
              "9:52",
              "10:37",
              "11:22",
              "12:07",
              "12:42",
            ],
          },
          {
            naziv: "Centar",
            vremena: [
              "5:35",
              "6:30",
              "7:05",
              "7:50",
              "8:35",
              "9:20",
              "10:05",
              "10:50",
              "11:35",
              "12:20",
              "12:55",
              "13:50",
              "15:00",
              "16:15",
              "17:45",
              "19:20",
              "20:35",
            ],
          },
          {
            naziv: "Novo groblje",
            vremena: [
              "5:45",
              "6:45",
              "7:20",
              "8:05",
              "8:50",
              "9:35",
              "10:20",
              "11:05",
              "11:50",
              "12:35",
              "13:05",
              "14:05",
              "15:10",
              "16:25",
              "17:55",
              "19:25",
              "20:50",
            ],
          },
        ],
      },
      {
        id: 4,
        linija: "Novo groblje - Trpinjska cesta",
        stanice: [
          {
            naziv: "Novo groblje",
            vremena: [
              "5:30",
              "6:00",
              "6:30",
              "6:45",
              "7:20",
              "8:05",
              "8:50",
              "9:35",
              "10:20",
              "11:05",
              "11:50",
              "12:35",
              "13:20",
              "14:05",
              "15:10",
              "16:30",
              "18:00",
              "19:30",
              "21:00",
            ],
          },
          {
            naziv: "Centar",
            vremena: [
              "5:35",
              "6:10",
              "6:40",
              "6:55",
              "7:30",
              "9:00",
              "9:00",
              "9:45",
              "10:30",
              "11:15",
              "12:00",
              "12:45",
              "13:30",
              "14:15",
              "15:20",
              "16:40",
              "18:10",
              "19:40",
              "21:10",
            ],
          },
          {
            naziv: "Borovo naselje",
            vremena: [
              "5:50",
              "6:25",
              "6:55",
              "7:10",
              "7:45",
              "9:15",
              "9:15",
              "10:00",
              "10:45",
              "11:30",
              "12:15",
              "13:00",
              "13:45",
              "14:30",
              "15:35",
              "16:55",
              "18:25",
              "19:55",
              "21:25",
            ],
          },
          {
            naziv: "Lipovača",
            vremena: [
              "6:00",
              "7:20",
              "10:10",
              "12:25",
              "13:10",
              "13:55",
              "15:45",
              "17:05",
              "20:05",
            ],
          },
          {
            naziv: "Trpinjska cesta",
            vremena: [
              "6:35",
              "7:55",
              "9:25",
              "9:25",
              "10:55",
              "11:40",
              "14:05",
              "14:35",
              "18:35",
              "21:30",
            ],
          },
        ],
      },
      {
        id: 5,
        linija: "Radnički dom - Dom zdravlja",
        stanice: [
          {
            naziv: "Radnički dom",
            vremena: ["7:05", "9:00", "12:00"],
          },
          {
            naziv: "Lužac",
            vremena: ["7:10", "9:05", "12:05"],
          },
          {
            naziv: "Bolnica",
            vremena: ["7:15", "9:10", "12:10"],
          },
          {
            naziv: "Tržnica",
            vremena: ["7:17", "9:12", "12:12"],
          },
          {
            naziv: "Dom zdravlja",
            vremena: ["7:19", "9:15", "12:15"],
          },
        ],
      },
      {
        id: 6,
        linija: "Dom zdravlja - Radnički dom",
        stanice: [
          {
            naziv: "Dom zdravlja",
            vremena: ["7:20", "11:05", "13:20"],
          },
          {
            naziv: "Tržnica",
            vremena: ["7:24", "11:09", "13:24", "15:15", "19:30"],
          },
          {
            naziv: "Bolnica",
            vremena: ["7:28", "11:13", "13:28"],
          },
          {
            naziv: "Lužac",
            vremena: ["7:32", "11:17", "13:32", "15:20", "19:35"],
          },
          {
            naziv: "Radnički dom",
            vremena: ["7:35", "11:20", "13:35"],
          },
        ],
      },
    ],
    []
  );

  const timeToMinutes = (timeStr) => {
    if (timeStr === "/") return Infinity;
    const [hours, minutes] = timeStr.split(/[:.]/).map(Number);
    return hours * 60 + minutes;
  };

  const getNextThreeDepartures = (vremena) => {
    if (!vremena || !Array.isArray(vremena)) return "";

    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    const departures = vremena
      .map((vreme) => {
        if (!vreme) return null;
        return {
          vreme,
          minutes: timeToMinutes(vreme),
        };
      })
      .filter(Boolean)
      .filter((dep) => dep.minutes >= currentMinutes)
      .sort((a, b) => a.minutes - b.minutes)
      .slice(0, 3)
      .map((dep) => dep.vreme);

    return departures.length > 0 ? departures.join(", ") : "Nema polazaka";
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setNextDepartures([]);
      setShowInitialLines(true);
      return;
    }

    setShowInitialLines(false);

    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    let allDepartures = [];

    redVoznjeData.forEach((linija) => {
      linija.stanice.forEach((stanica) => {
        if (stanica.naziv.toLowerCase().includes(searchTerm.toLowerCase())) {
          stanica.vremena.forEach((vreme) => {
            const depTime = timeToMinutes(vreme);
            if (depTime >= currentMinutes) {
              allDepartures.push({
                linija: linija.linija,
                linijaId: linija.id,
                stanica: stanica.naziv,
                vreme: vreme,
                minutes: depTime,
              });
            }
          });
        }
      });
    });

    allDepartures.sort((a, b) => a.minutes - b.minutes);
    setNextDepartures(allDepartures.slice(0, 3));
  }, [searchTerm, redVoznjeData]);

  const filteredAllLines = redVoznjeData
    .map((linija) => ({
      ...linija,
      stanice: linija.stanice.filter((stanica) =>
        stanica.naziv.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((linija) => linija.stanice.length > 0);

  const filteredSelectedLine = selectedLine
    ? redVoznjeData
        .filter((linija) => linija.id === selectedLine)
        .map((linija) => ({
          ...linija,
          stanice: linija.stanice.filter((stanica) =>
            stanica.naziv.toLowerCase().includes(searchTerm.toLowerCase())
          ),
        }))
        .filter((linija) => linija.stanice.length > 0)
    : [];

  const handleLineClick = (linijaId, stationName) => {
    setSelectedLine(linijaId);
    setSelectedStation(stationName);
  };

  const handleBack = () => {
    setSelectedLine(null);
    setSelectedStation(null);
    setSearchTerm("");
  };
  const handleLineClickFromList = (linijaId) => {
    setSelectedLine(linijaId);
    setSelectedStation(null);
    setSearchTerm(""); // Resetujemo pretragu
  };

  const handleLineClickFromSearch = (linijaId, stationName) => {
    setSelectedLine(linijaId);
    setSelectedStation(stationName);
  };

  return (
    <div className="red-voznje">
      <h1>Red vožnje</h1>

      {!selectedLine ? (
        <>
          <RedVoznjeControls
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />

          {showInitialLines && searchTerm === "" && (
            <div className="all-lines">
              <h2>Sve linije:</h2>
              <ul className="lines-list">
                {redVoznjeData.map((linija) => (
                  <li key={linija.id}>
                    <button
                      onClick={() => handleLineClickFromList(linija.id)}
                      className="linija-button"
                    >
                      {linija.linija}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Rezultati pretrage */}
          {searchTerm && filteredAllLines.length > 0 && (
            <div className="linije-list">
              <h2></h2>
              <table className="linije-table">
                <thead>
                  <tr>
                    <th>Linija</th>
                    <th>Stanica</th>
                    <th>Sledeća tri polaska</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAllLines.map((linija) => {
                    const stanica = linija.stanice.find((s) =>
                      s.naziv.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                    return (
                      <tr key={linija.id}>
                        <td>
                          <button
                            onClick={() =>
                              handleLineClickFromSearch(
                                linija.id,
                                stanica.naziv
                              )
                            }
                            className="linija-button"
                          >
                            {linija.linija}
                          </button>
                        </td>
                        <td>{stanica.naziv}</td>
                        <td>{getNextThreeDepartures(stanica.vremena)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="selected-line-header">
            <h2>{redVoznjeData.find((l) => l.id === selectedLine).linija}</h2>
            <button onClick={handleBack} className="back-button">
              ← Nazad na sve linije
            </button>
          </div>

          {/* PRIKAZ ZAVISNO OD TOGA DA LI JE IZABRANO PREKO PRETRAGE ILI DIREKTNO */}
          {selectedStation ? (
            // PRIKAZ KADA JE IZABRANO PREKO PRETRAGE
            <>
              <div className="search-results-container">
                <table className="linije-table">
                  <thead>
                    <tr>
                      <th>Linija</th>
                      <th>Stanica</th>
                      <th>Sledeća tri polaska</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {
                          redVoznjeData.find((l) => l.id === selectedLine)
                            .linija
                        }
                      </td>
                      <td>{selectedStation}</td>
                      <td>
                        {getNextThreeDepartures(
                          redVoznjeData
                            .find((l) => l.id === selectedLine)
                            .stanice.find((s) => s.naziv === selectedStation)
                            .vremena
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="station-full-schedule">
                <h3> {selectedStation}:</h3>
                <div className="time-slots-container">
                  {redVoznjeData
                    .find((l) => l.id === selectedLine)
                    .stanice.find((s) => s.naziv === selectedStation)
                    .vremena.map((vreme, index) => (
                      <div key={index} className="time-slot">
                        {vreme}
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            // PRIKAZ KADA JE DIREKTNO IZABRANA LINIJA
            <>
              <div className="full-line-schedule">
                <h3></h3>
                <RedVoznjeTable
                  data={redVoznjeData.filter((l) => l.id === selectedLine)}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
export default RedVoznje;
