import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import RadnoVreme from "./pages/RadnoVreme";
import RedVoznje from "./pages/RedVoznje";
import Oglasi from "./pages/Oglasi";

import ONama from "./pages/ONama";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/radno-vreme" element={<RadnoVreme />} />
          <Route path="/red-voznje" element={<RedVoznje />} />
          <Route path="/oglasi" element={<Oglasi />} />

          <Route path="/o-nama" element={<ONama />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
