import React, { useState } from "react";
import "./OglasiForma.css";

function OglasiForma({ dodajOglas }) {
  const [naslov, setNaslov] = useState("");
  const [opis, setOpis] = useState("");
  const [slika, setSlika] = useState("");
  const [kontakt, setKontakt] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const noviOglas = { naslov, opis, slika, kontakt };
    dodajOglas(noviOglas);
    setNaslov("");
    setOpis("");
    setSlika("");
    setKontakt("");
  };

  return (
    <form onSubmit={handleSubmit} className="oglas-forma">
      <h2>Postavi Oglas</h2>
      <input
        type="text"
        placeholder="Naslov"
        value={naslov}
        onChange={(e) => setNaslov(e.target.value)}
        required
      />
      <textarea
        placeholder="Opis"
        value={opis}
        onChange={(e) => setOpis(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="URL slike"
        value={slika}
        onChange={(e) => setSlika(e.target.value)}
      />
      <input
        type="text"
        placeholder="Kontakt informacije"
        value={kontakt}
        onChange={(e) => setKontakt(e.target.value)}
        required
      />
      <button type="submit">Postavi Oglas</button>
    </form>
  );
}

export default OglasiForma;
