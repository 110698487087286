import React, { useState } from "react";
import "./ONama.css";

function ONama() {
  const [formData, setFormData] = useState({
    ime: "",
    email: "",
    poruka: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.ime) newErrors.ime = "Ime je obavezno";
    if (!formData.email) {
      newErrors.email = "Email je obavezan";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Email nije validan";
    }
    if (!formData.poruka) newErrors.poruka = "Poruka je obavezna";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      console.log("Poruka poslata:", formData);
      setErrors({});
      setIsSubmitted(true);
      setFormData({ ime: "", email: "", poruka: "" });
      setTimeout(() => setIsSubmitted(false), 3000);
    }
  };

  return (
    <div className="o-nama-container">
      <h1>O Nama</h1>

      <section className="sekcija">
        <h2>Ko smo mi?</h2>
        <p>
          AllAboutVu je platforma posvećena pružanju korisnih informacija
          građanima Vukovara.
        </p>
      </section>

      <section className="sekcija">
        <h2>Naša misija</h2>
        <p>Želimo da spojimo sve bitne informacije o gradu na jednom mestu.</p>
      </section>

      <section className="sekcija kontakt-sekcija">
        <h2>Kontaktirajte nas</h2>

        <div className="kontakt-info">
          <p>
            <strong>Adresa:</strong> Vukovar, Hrvatska
          </p>
          <p>
            <strong>Telefon:</strong> +385 123 456 789
          </p>
          <p>
            <strong>Email:</strong> info@allaboutvu.hr
          </p>
        </div>

        <div className="kontakt-forma">
          <h3>Pošaljite nam poruku</h3>
          {isSubmitted && (
            <div className="success-message">Poruka je uspešno poslata!</div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Ime i Prezime:</label>
              <input
                type="text"
                name="ime"
                value={formData.ime}
                onChange={handleChange}
              />
              {errors.ime && <span className="error">{errors.ime}</span>}
            </div>

            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>

            <div className="form-group">
              <label>Poruka:</label>
              <textarea
                name="poruka"
                value={formData.poruka}
                onChange={handleChange}
              />
              {errors.poruka && <span className="error">{errors.poruka}</span>}
            </div>

            <button type="submit">Pošalji</button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default ONama;
