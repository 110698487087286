import React, { useEffect, useState } from "react";
import "./Home.css";
import axios from "axios";

function Home() {
  const [funFact, setFunFact] = useState("");
  const [waterLevel, setWaterLevel] = useState(null);
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Učitavanje zanimljivosti
    fetchFunFact();

    // Učitavanje vremena i vodostaja
    fetchWeatherData();
    fetchWaterLevelData();
  }, []);

  const fetchFunFact = async () => {
    try {
      const response = await axios.get(
        "https://uselessfacts.jsph.pl/api/v2/facts/random?language=en"
      );
      setFunFact(response.data.text);
    } catch (error) {
      console.error("Error fetching fun fact:", error);
      setFunFact(
        "Did you know? The first oranges weren't orange - they were green."
      );
    }
  };

  const fetchWeatherData = async () => {
    try {
      // Ovo je primer API poziva - možete koristiti OpenWeatherMap ili drugi servis
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?q=Vukovar,HR&units=metric&appid=62112f7f00f5927964acee120438e33d`
      );
      setWeather({
        temp: response.data.main.temp,
        description: response.data.weather[0].description,
        icon: response.data.weather[0].icon,
      });
    } catch (error) {
      console.error("Error fetching weather:", error);
      setWeather({
        temp: "N/A",
        description: "Podaci nisu dostupni",
        icon: "01d",
      });
    }
  };

  const fetchWaterLevelData = async () => {
    try {
      // Ovo je primer - trebaće vam pravi API za vodostaj Dunava u Vukovaru
      const response = await axios.get("YOUR_WATER_LEVEL_API_ENDPOINT");
      setWaterLevel(response.data.level);
    } catch (error) {
      console.error("Error fetching water level:", error);
      setWaterLevel("N/A");
    } finally {
      setLoading(false);
    }
  };

  // Lista važnih telefona
  const importantPhones = [
    { name: "Hitna pomoć", number: "124" },
    { name: "Policija", number: "122" },
    { name: "Vatrogasci", number: "123" },
    { name: "Gradska uprava", number: "021/123-456" },
    { name: "Bolnica", number: "021/789-012" },
    { name: "Dom zdravlja", number: "021/345-678" },
    { name: "Autobuski prevoz", number: "021/901-234" },
    { name: "Vodovod", number: "021/567-890" },
    { name: "Elektrodistribucija", number: "021/234-567" },
    { name: "Informacije", number: "021/890-123" },
  ];

  // Korisni linkovi
  const usefulLinks = [
    { name: "Grad Vukovar", url: "https://www.vukovar.hr" },
    { name: "Turistička zajednica", url: "https://www.turizamvukovar.hr" },
    { name: "Vukovarske novine", url: "https://www.vukovarske-novine.hr" },
    { name: "Muzej Vučedol", url: "https://www.muzej-vucedol.hr" },
    { name: "Vukovarski vodovod", url: "https://www.vodovod.hr" },
    { name: "Javni prijevoz", url: "https://www.gsp.hr" },
    { name: "Vukovarska bolnica", url: "https://www.bolnica-vukovar.hr" },
    { name: "Gradska knjižnica", url: "https://www.gk-vukovar.hr" },
    { name: "Kulturni centar", url: "https://www.kc-vukovar.hr" },
    { name: "Sportski centar", url: "https://www.sc-vukovar.hr" },
  ];

  // Predstojeći događaji
  const upcomingEvents = [
    {
      title: "Dan grada Vukovara",
      date: "15. Novembar",
      location: "Centar grada",
    },
    {
      title: "Vukovarske jeseni",
      date: "25. Oktobar",
      location: "Kulturni centar",
    },
    { title: "Sajam vina", date: "10. Decembar", location: "Gradski trg" },
  ];

  return (
    <div className="home">
      {/* Hero Sekcija */}
      <div className="hero-section">
        <h1 className="hero-title">Dobrodošli u Vukovar</h1>
        <p className="hero-subtitle">Vaš izvor informacija o gradu!</p>
      </div>

      {/* Fun Fact sekcija umesto brojača pregleda */}
      <div className="fun-fact">
        <p className="fun-fact-text">Zanimljivost: {funFact}</p>
      </div>

      {/* Kartice */}
      <div className="cards">
        {/* 1. Predstojeći događaji */}
        <div className="card events-card">
          <h2>Predstojeći događaji</h2>
          <ul className="events-list">
            {upcomingEvents.map((event, index) => (
              <li key={index}>
                <h3>{event.title}</h3>
                <p>
                  <strong>Datum:</strong> {event.date}
                </p>
                <p>
                  <strong>Lokacija:</strong> {event.location}
                </p>
              </li>
            ))}
          </ul>
          <div className="events-icon">📅</div>
        </div>

        {/* 2. Važni telefoni */}
        <div className="card important-phones-card">
          <h2>Važni telefoni</h2>
          <ul className="phones-list">
            {importantPhones.map((phone, index) => (
              <li key={index}>
                <span className="phone-name">{phone.name}:</span>
                <span className="phone-number">{phone.number}</span>
              </li>
            ))}
          </ul>
          <div className="phone-icon">📞</div>
        </div>

        {/* 3. Vreme i vodostaj */}
        <div className="card weather-card">
          <h2>Vreme i vodostaj</h2>
          {loading ? (
            <p>Učitavanje podataka...</p>
          ) : (
            <div className="weather-data">
              <div className="weather-info">
                <h3>Trenutno vreme</h3>
                {weather && (
                  <>
                    <p>Temperatura: {weather.temp}°C</p>
                    <p>Stanje: {weather.description}</p>
                    <img
                      src={`https://openweathermap.org/img/wn/${weather.icon}.png`}
                      alt="Vremenska ikona"
                    />
                  </>
                )}
              </div>
              <div className="water-level">
                <h3>Vodostaj Dunava</h3>
                <p>{waterLevel} cm</p>
              </div>
            </div>
          )}
          <div className="weather-icon">🌤️</div>
        </div>

        {/* 4. Korisni linkovi */}
        <div className="card links-card">
          <h2>Korisni linkovi</h2>
          <ul className="links-list">
            {usefulLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
          <div className="links-icon">🔗</div>
        </div>
      </div>
    </div>
  );
}

export default Home;
