import React from "react";
import "./RedVoznjeControls.css";

function RedVoznjeControls({ searchTerm, setSearchTerm }) {
  return (
    <div className="controls">
      <input
        type="text"
        placeholder="Pretraži po stanici..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
}

export default RedVoznjeControls;
