import React, { useState } from "react";
import OglasiForma from "../components/OglasiForma";
import OglasiLista from "../components/OglasiLista";
import "./Oglasi.css";

function Oglasi() {
  const [oglasi, setOglasi] = useState([]);

  // Funkcija za dodavanje novog oglasa
  const dodajOglas = (noviOglas) => {
    setOglasi([...oglasi, { ...noviOglas, id: Date.now(), odobren: false }]);
  };

  // Funkcija za odobravanje oglasa
  const odobriOglas = (id) => {
    setOglasi(
      oglasi.map((oglas) =>
        oglas.id === id ? { ...oglas, odobren: true } : oglas
      )
    );
  };

  return (
    <div className="oglasi">
      <h1>Oglasi</h1>

      {/* Forma za slanje oglasa */}
      <OglasiForma dodajOglas={dodajOglas} />

      {/* Prikaz svih oglasa */}
      <OglasiLista oglasi={oglasi} odobriOglas={odobriOglas} />
    </div>
  );
}

export default Oglasi;
